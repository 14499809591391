<div class="containerGeneral">
  <div>
    <img id="logoGo" src="assets/media/img/academica.png" alt="logo" />
  </div>

  <div class="infoContainer">
    <section *ngIf="form == true">
      <div class="title">
        <h2 class="marbot">Preguntas para el invitado</h2>
      </div>

      <div class="formgroup">
        <label for="idnombreal">Nombre </label>
        <input
          id="nombre"
          class="form-control"
          type="text"
          placeholder="Escribir.."
          [(ngModel)]="nombre"
        />
      </div>

      <div class="formgroup">
        <label for="idnombreal">Apellidos </label>
        <input
          id="apellido"
          class="form-control"
          type="text"
          placeholder="Escribir.."
          [(ngModel)]="apellidos"
        />
      </div>
      <div class="formgroup">
        <label for="idnombreal">Colegio </label>
        <input
          id="apellido"
          class="form-control"
          type="text"
          placeholder="Escribir.."
          [(ngModel)]="colegio"
        />
      </div>
      <div class="formgroup">
        <label for="idnombreal">Provincia </label>
        <input
          id="apellido"
          class="form-control"
          type="text"
          placeholder="Escribir.."
          [(ngModel)]="provincia"
        />
      </div>
      <div class="formgroup">
        <label for="idnombreal">Id de estudiante </label>
        <input
          id="apellido"
          class="form-control"
          type="text"
          placeholder="Escribir.."
          [(ngModel)]="idEstudiante"
        />
      </div>
      <div class="formgroup">
        <label for="idnombreal">¿Está participando tu familia también en esta sesión? </label>
        <select [(ngModel)]="conPadres">
          <option value="si">Si</option>
          <option value="no">No</option>
        </select>
      </div>

      <div class="formgroup">
        <label for="iddirecc">Preguntas: </label>
        <textarea
          maxlength="300"
          id="iddirecc"
          class="form-control"
          placeholder="Escriba su pregunta..."
          [(ngModel)]="motivo"
        ></textarea>
      </div>
      <div class="response" *ngIf="error == true">
        <p>{{ response }}</p>
      </div>
      <div class="botones">
        <div class="trans">
          <button (click)="createPacienteSinVideo()">
            Solo enviar pregunta
          </button>
        </div>
        <div class="trans">
          <button (click)="createPaciente()">Entrar en la conferencia</button>
        </div>
      </div>

      <div class="trans">
        <a
          href="https://in2.thecampus.education/webapp3/#/?conference=salapruebas"
          target="_blank"
        >
          <b>Prueba de conexión</b>
        </a>
      </div>
    </section>

    <div class="registrado" *ngIf="registrado">
      <p>
        <b>Preguntas realizadas</b>
      </p>
      <p>Será transferido a la sala de espera y en breve será atendido.</p>
      <img src="/assets/media/img/ok.svg" alt="ok logo" />
    </div>
    <div class="registrado" *ngIf="registradoPregunta">
      <p>
        <b>Preguntas realizadas</b>
      </p>
      <p>Muchas gracias.</p>
      <img src="/assets/media/img/ok.svg" alt="ok logo" />
    </div>
  </div>
</div>
